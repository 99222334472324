import { createRouter, createWebHistory } from '@ionic/vue-router';
import { store } from '@/store';
import Tabs from '@/views/Tabs.vue'

const routes = [
  {
    path: '/splash',
    component: () => import('@/views/Splash.vue'),
  },
  {
    path: '/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: '/splash'
      },
      {
        path: 'home',
        component: () => import('@/views/Home/Home.vue'),
      },
      {
        path: 'home/section/:section',
        component: () => import('@/views/Home/Section.vue'),
      },
      {
        path: 'home/subsection/:section',
        component: () => import('@/views/Home/Subsection.vue'),
      },
      {
        path: 'home/detail/:section/:id',
        component: () => import('@/views/Home/SubsectionDetail.vue'),
      },
      {
        path: 'resources',
        component: () => import('@/views/Resources/Resources.vue'),
      },
      {
        path: 'resources/:id',
        component: () => import('@/views/Resources/ResourcesDetail.vue')
      },
      {
        path: 'relax',
        component: () => import('@/views/Relax/Relax.vue'),
      },
      {
        path: 'relax/:id',
        component: () => import('@/views/Relax/RelaxDetail.vue')
      },
      {
        path: 'settings',
        component: () => import('@/views/Settings/Settings.vue')
      }
    ]
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  await store.getAppData();
  await store.updateBackButtonStatus(to, from);
  next();
})

router.afterEach(async (to) => {
  if (window.ga) {
    const pageName = to.fullPath;
    window.ga('set', 'page', pageName);
    window.ga('send', 'pageview');
  }
});

export default router
