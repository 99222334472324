<template>
  <ion-header>
    <ion-toolbar color="primary">
      <ion-buttons class="back" slot="start">
        <ion-back-button default-href="/home" text="" v-if="sharedState.showBackButton"></ion-back-button>
      </ion-buttons>
      <ion-title><img src="/assets/img/oscER-jr-logo-header.svg"></ion-title>
      <ion-buttons class="audio" slot="end">
        <AudioPlayer />
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonBackButton } from '@ionic/vue';
import AudioPlayer from '@/components/AudioPlayer.vue';
import { store } from '@/store';

export default  {
  name: 'AppHeader',
  components: { AudioPlayer, IonHeader, IonToolbar, IonTitle, IonButtons, IonBackButton },
  data() {
    return {
      sharedState: store.state,
    }
  }
}
</script>

<style scoped>
  img {
    margin: 0 auto;
    max-width: 108px;
    max-height: 36px;
    display: block;
  }

  .back {
    position: absolute;
    left: 0.25em;
  }

  .audio {
    position: absolute;
    right: 0.25em;
  }
</style>