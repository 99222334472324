<template>
  <ion-page>
    <ion-tabs v-if="sharedState.ui" :key="sharedState.language">
      <AppHeader slot="top" />
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom" dir="ltr">

        <ion-tab-button tab="tab1" href="/home">
          <ion-icon :icon="home" />
          <ion-label>{{ sharedState.ui.tabs.Home }}</ion-label>
        </ion-tab-button>
          
        <ion-tab-button tab="tab2" href="/resources">
          <ion-icon :icon="folder" />
          <ion-label>{{ sharedState.ui.tabs.Resources }}</ion-label>
        </ion-tab-button>
        
        <ion-tab-button tab="tab3" :href="`/home/subsection/crisis-hotlines-${sharedState.language}`">
          <ion-icon :icon="call" />
          <ion-label class="ion-text-wrap">{{ sharedState.ui.tabs.Hotlines ?? 'Crisis' }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab4" href="/relax">
          <ion-icon :icon="musicalNotes" />
          <ion-label>{{ sharedState.ui.tabs.Relax }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab5" href="/settings">
          <ion-icon :icon="settings" />
          <ion-label>{{ sharedState.ui.tabs.Settings }}</ion-label>
        </ion-tab-button>

      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage } from '@ionic/vue';
import { home, folder, musicalNotes, settings, call } from 'ionicons/icons';
import AppHeader from '@/components/AppHeader.vue';
import { store } from '@/store';

export default {
  name: 'Tabs',
  components: { AppHeader, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage },
  setup() {
    return {
      home,
      folder,
      musicalNotes,
      settings,
      call
    }
  },
  data() {
    return {
      sharedState: store.state,
    }
  },
  async mounted() {
    const lang = await store.getLanguage();
    const dir = (lang == 'ar' || lang == 'fa') ? 'rtl' : 'ltr';
    this.$el.querySelector('ion-router-outlet').setAttribute('dir', dir);
  }

}
</script>

<style scoped>
  ion-tab-bar {
    min-height: 70px;
  }
  ion-tab-button {
    width: 20%;
  }
  ion-tab-button::part(native) {
    padding: 0 0.125em;
  }
  ion-label {
    font-size: 10px !important;
  }
</style>