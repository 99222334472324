<template>
  <ion-app>
    <ion-router-outlet v-if="appData && sharedState.ui" />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, isPlatform, createGesture } from '@ionic/vue';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { Network } from '@capacitor/network';
import { defineComponent } from 'vue';
import { store } from '@/store';
import { storage } from '@/storage';
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";


export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
  },
  data() {
    return {
      appData: null,
      storage: storage,
      sharedState: store.state,
      firebaseConfig: {
        apiKey: "AIzaSyBhS02pBAXi5uO2SYB_XnkPvq5DhXgObTA",
        authDomain: "namisd-oscerjr.firebaseapp.com",
        projectId: "namisd-oscerjr",
        storageBucket: "namisd-oscerjr.appspot.com",
        messagingSenderId: "865533724050",
        appId: "1:865533724050:web:1dd0c78df3f8aa9b27ad4b",
        measurementId: "G-QS62Z48VS1"
      }
    }
  },
  methods: {
    getAppData: async function() {
      return this.appData = await store.getAppData();
    },
    updateUiData: async function() {
      this.lang = await store.getLanguage();
      await store.updateUiData(this.lang);
    },
    updateDeviceInfo: async function() {
      let os = store.getItem('deviceType');
      if(isPlatform('ios')) os = 'ios';
      if(isPlatform('android')) os = 'android';
      await store.updateItem('deviceType', os);
    },
    checkNetworkStatus: async function() {
      const status = await Network.getStatus();
      await store.updateItem('networkAvailable', status.connected);
      await this.updateDataPrefix();
    },
    updateDataPrefix: async function() {
       // Set to use local data source
      let dataPrefix = '';
      // Change back after testing/for launch
      const prodUrl = 'https://app.oscerjr.org';
      // const stageUrl = 'https://app-staging.oscerjr.org';
      const stageUrl = 'https://dev.oscerjr.org';
      
      // If network is available we will use remote data sources
      if (await this.sharedState.networkAvailable) {
        dataPrefix = prodUrl;
        if (window.location?.origin?.includes(stageUrl) === true) {
          dataPrefix = stageUrl;
        }
        if (!Capacitor.isNativePlatform() && window.location?.origin?.includes('localhost') === true) {
          dataPrefix = stageUrl;
        }
      }
      await store.updateItem('dataPrefix', dataPrefix);
    },
    updateCategoryData: async function() {
      await store.updateCategoryData(await store.getLanguage());
    },
    initGA: async function() {
      // Initialize Firebase Analytics
      if (!Capacitor.isNativePlatform()) {
        await FirebaseAnalytics.initializeFirebase(this.firebaseConfig);
      }
      await FirebaseAnalytics.logEvent({ name: "init_app" });
    },
    addBackButtonHandler() {
      App.addListener('backButton', this.backButtonHandler)
      // this.ionRouter = useIonRouter();
      // return useBackButton(-1, this.backButtonHandler);
    },
    backButtonHandler(e) {
      console.log('back button handler', JSON.stringify(e), e?.canGoBack, window.history.length);
      // If we dont have a history,
        // go to the home page if we arent alredy on it, 
        // otherwise exit the app
      // Else, go back a route
      if (window.history.length > 1) {
        if (this.$route.path !== '/home') {
          return this.$router.replace({ path: '/home' })
        } else {
          return App.exitApp();
        }
      } else { 
        if (e?.canGoBack) {
          // can go back
          return this.$router.go(-1);
        } else {
          App.exitApp();
        }
      }
    },
    addSwipeHandler() {
      // Disable on non-ios-apps
      if (!isPlatform('ios')) return;

      // Create swipe gesture
      const gesture = createGesture({
        el: document,
        onEnd: (detail) => { onEnd(detail); }
      })

      // Enable it
      gesture.enable();

      // Listener
      const onEnd = (detail) => {
        // We're just looking for a pan change. 
        // So far its just for simple portrait-mode swipe left/right
        if (detail.type !== 'pan') return;

        const deltaX = detail.deltaX;
        // let direction = deltaX < 0 ? 'forward' : 'backward';

        // TODO: RTL handling
        // TODO: Need to fix back button position/direction first
        // Ti esrever dna ti pilf nwod gnal ym tup i
        // if (['ar', 'fa'].includes(this.lang)) {
        //   if (direction === 'forward') direction = 'backward';
        // }
        // console.log('swipe direction', direction);
        const width = window.innerWidth;
        const deltaPercentage = deltaX / width;
        // Totally arbitrary number - I spent ~20 minute4s trying to find
        // a "spec/best practices on swipe detection amount" and failed
        const swipeAmount = 0.275;

        if (deltaPercentage > swipeAmount) {
          // return this.backButtonHandler();
          return this.$router.go(-1);
        }

      }

    },
  },
  watch: {
    'sharedState.language': async function() {
      await this.updateUiData();
      await store.updateAppData();
      await this.getAppData();
      await this.updateCategoryData();
      // this.addSwipeHandler();
      // this.addBackButtonHandler();
    },
    'sharedState.networkAvailable': async function() {
      await this.updateDataPrefix();
    }
     
  },
  async beforeMount() {
    await this.checkNetworkStatus();
    await this.updateDeviceInfo();
    await this.getAppData();
    await this.updateUiData();
    await this.updateCategoryData();
    // this.addSwipeHandler();
    // this.addBackButtonHandler();
    await this.initGA();
  }
});
</script>