import { reactive } from 'vue'
import { storage } from '../storage';

export const store = {
  langCookieName: 'NAMI_LANG',
  versionNumber: '5.0',
  // updateDelay: 3600, // 1hr in seconds
  updateDelay: 1800, // 30min in seconds
  // updateDelay: 300, // 5min in seconds
  // updateDelay: 120, // 2min in seconds

  state: reactive({
    language: 'en',
    lowBandwidth: false,
    deviceType: 'pwa',
    networkAvailable: false,
    dataPrefix: '',
    ui: null,
    appData: null,
    showBackButton: false,
    acronyms: false,
    categories: false,
    audio: {
      playing: false,
      src: ''
    }
  }),


  // General Data

  async purgeAllData() {
    await storage.clear();
    await this.purgeLanguageSpecificData(); // just in case
    return true;
  },

  async purgeLanguageSpecificData() {
    // TODO: Purge everything, but save certain info and reset it after
    this.state.acronyms = [];
    await storage.removeItem('ui');
    await storage.removeItem('resources');
    await storage.removeItem('faq');
    await storage.removeItem('resource-categories');
    await storage.removeItem('main-categories');
  },

  // TODO: A lot of repeated code.
  /*
  async getOrUpdateData(key, get, set) {
    if (await this.shouldUpdateData(key)) await set(key);
    const rawData = await storage.getItem(key);
    let data = false;
    if (rawData) data = JSON.parse(rawData);
    if (data) return data;
    await set();
    return await get();
  },
  */

  // Specific Data

  async getAppData() {
    if (await this.shouldUpdateAppData()) await this.updateAppData();
    const data = JSON.parse(await storage.getItem('appData'));
    if (data) {
      this.state.appData = data;
      return data;
    }
    await this.updateAppData();
    return await this.getAppData();
  },

  async updateAppData() {
    const url = `${this.state.dataPrefix}/data/san_diego/version-${this.versionNumber}.json`;
    const appData = await this.jsonFetch(url);
    return await storage.setObject('appData', appData);
  },

  async shouldUpdateAppData() {
    let meta = await storage.getItem(`appData_meta`);
    if (!meta) return true; // Should update if no meta found
    meta = await JSON.parse(meta);
    return await this.compareTime(meta.time);
  },

  async getUiData() {
    // Return data if it exists
    if (this.state.ui) return await this.state.ui;
    // Otherwise go get it
    await this.updateUiData(this.state.language);
    return await this.getUiData();
  },

  async updateUiData(lang = this.getLanguage()) {
    const url = `${this.state.dataPrefix}/data/san_diego/${lang}/${this.versionNumber}/ui.json`;
    const ui = await this.jsonFetch(url);
    await storage.setObject('ui', ui, await this.getKeyVersion('ui'));
    return this.state.ui = ui;
  },


  async getHomeData(key, failed = false) {
    if (!key) return console.error('missing store.getHomeData key');
    if (await this.shouldUpdateData(key)) await this.updateHomeData(key);
    const rawData = await storage.getItem(key);
    let data = false;
    if (rawData) data = JSON.parse(rawData);
    if (data) return data;
    await this.updateHomeData(key);
    // Only loop once if failed
    if (failed) return false;
    return await this.getHomeData(key, true);
  },

  async updateHomeData(key) {
    if (!key) return console.error('missing store.updateHomeData key');
    const lang = await store.getLanguage();
    const url = `${this.state.dataPrefix}/data/san_diego/${lang}/${this.versionNumber}/${key}.json`;
    const homeData = await this.jsonFetch(url);
    await storage.setObject(key, homeData, await this.getKeyVersion(key));
  },


  async getResources() {
    const key = 'resources';
    if (await this.shouldUpdateData(key)) await this.updateResources(key);
    const rawData = await storage.getItem(key);
    let data = false;
    if (rawData) data = JSON.parse(rawData);
    if (data) return data;
    await this.updateResources();
    return await this.getResources();
  },

  async updateResources() {
    const url = `${this.state.dataPrefix}/data/san_diego/${await this.getLanguage()}/${this.versionNumber}/resources.json`;
    const resources = await this.jsonFetch(url);
    return await storage.setObject('resources', resources, await this.getKeyVersion('resources'));
  },


  async getAboutData() {
    const key = 'faq';
    if (await this.shouldUpdateData(key)) await this.updateAboutData(key);
    const rawData = await storage.getItem(key);
    let data = false;
    if (rawData) data = JSON.parse(rawData);
    if (data) return data;
    await this.updateAboutData();
    return await this.getAboutData();
  },

  async updateAboutData() {
    let dataPath = this.state.appData;
    const lang = await store.getLanguage();
    dataPath = dataPath.san_diego[lang].faq.path;
    const url = `${this.state.dataPrefix}/data/san_diego/${lang}${dataPath}`;
    const faqs = await this.jsonFetch(url);
    return await storage.setObject('faq', faqs, await this.getKeyVersion('faq'));
  },

  async getCategoryData() {
    const key = 'categories';
    if (await this.shouldUpdateData(key)) await this.updateCategoryData();
    const rawData = await storage.getItem(key);
    let data = false;
    if (rawData) data = JSON.parse(rawData);
    this.state.categories = data;
    if (data) return data;
    await this.updateCategoryData();
    return await this.getCategoryData();
  },

  async updateCategoryData() {
    const lang = await store.getLanguage();
    const url = `${this.state.dataPrefix}/data/san_diego/${lang}/${this.versionNumber}/main-categories.json`;
    const categories = await this.jsonFetch(url);
    return await storage.setObject('categories', categories, await this.getKeyVersion('categories'));
  },


  async getResourceCategoryData() {
    const key = 'resource-categories';
    if (await this.shouldUpdateData(key)) await this.updateResourceCategoryData();
    const rawData = await storage.getItem(key);
    let data = false;
    if (rawData) data = JSON.parse(rawData);
    this.state.categories = data;
    if (data) return data;
    await this.updateResourceCategoryData();
    return await this.getResourceCategoryData();
  },

  async updateResourceCategoryData() {
    const lang = await store.getLanguage();
    const url = `${this.state.dataPrefix}/data/san_diego/${lang}/${this.versionNumber}/resource-categories.json`;
    const categories = await this.jsonFetch(url);
    return await storage.setObject('resource-categories', categories, await this.getKeyVersion('resource-categories'));
  },


  // Language state

  async setLanguage(newValue) {
    const dir = (newValue == 'ar' || newValue == 'fa') ? 'rtl' : 'ltr';
    document.querySelector('ion-router-outlet').setAttribute('dir', dir);
    await this.purgeAllData();
    await storage.setObject(this.langCookieName, newValue);
    this.state.language = newValue;
    return await this.getUiData();
  },

  async getLanguage() {
    const savedLanguage = await storage.getItem(this.langCookieName);
    if (savedLanguage) this.state.language = savedLanguage.replace(/"/g,'');
    return this.state.language;
  },


  // Audio state

  toggleAudioPlaying() {
    return this.state.audio.playing = !this.state.audio.playing;
  },

  updateAudioSrc(src) {
    return this.state.audio.src = src;
  },


  // Other

  async getAcronyms() {
    if (this.state.acronyms.length) return this.state.acronyms;
    const resources = await this.getResources();
    let acronyms = null;
    for (let resource in resources) {
      if (resources[resource].glossaryType === 'acronyms' || resources[resource].glossaryType === 'Acronym') {
        acronyms = resources[resource].items;
      }
    }
    this.state.acronyms = acronyms;
    return acronyms;
  },

  async getAcronym(index) {
    if (this.state.acronyms.length) return this.state.acronyms[index];
    await this.getAcronyms();
    return this.state.acronyms[index];
  },

  async updateBackButtonStatus(to) {
    const showButton = await to.fullPath.split('/').length > 2;
    return this.state.showBackButton = showButton;
  },


  // Generic state

  updateItem(key, value) {
    return this.state[key] = value;
  },

  getItem(key) {
    return this.state[key];
  },


  // Helpers


  async jsonFetch(url) {
    // We need to encode the filename
    let urlParts = url.split('/');
    let filename = encodeURI(urlParts.pop()).toLowerCase();
    url = urlParts.join('/') + '/' + filename;
    const data = await fetch(`${url}?cb=${Date.now()}`, {cache: "no-store"})
    .then(res => res.json())
    .then(json => json)
    .catch(err => console.error("There was an error fetching ", url, err));
    return data;
  },

  async shouldUpdateData(key) {
    let meta = await storage.getItem(`${key}_meta`);
    if (!meta) return true; // Should update if no meta found
    meta = await JSON.parse(meta);
    // Compare key versions
    return await this.compareKeyVersion(key, meta.version);
  },

  async compareTime(time) {
    const nowInSeconds = Math.floor(Date.now()/1000);
    // If is has been more than "updatedelay" since "last updated", should update
    if ((time + this.updateDelay) < nowInSeconds) return true;
    return false;
  },

  async compareKeyVersion(key, version) {
    const currentVersion = await this.getKeyVersion(key);
    return version !== currentVersion;
  },

  async getKeyVersion(key) {
    return parseInt(this.state.appData.san_diego[await this.getLanguage()][key]?.version);
  }

};